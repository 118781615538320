<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div v-infinite-scroll="loadHistory" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class="flex flex-wrap flex-wrap md:flex-nowrap" v-for="(dates, week) in schedules" :key="week">
          <div class="flex flex-col flex-grow-1 w-1/2 md:w-full last:w-full" v-for="(detail, date) in dates" :key="`${week}${date}`">
            <label class="flex-grow-1 border-b border-t border-gray-300 py-3 text-sm font-semibold mb-2">
              {{ date | nlDateFormat }}
            </label>
            <label class="flex text-sm last:mb-2" v-for="(job, date) in detail.jobs" :key="`${week}${date}`">
              {{ job.job_name }} {{ job | timeSpan(detail.type) }}
            </label>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { history } from "@/services/schedule-service";
import { mapGetters } from 'vuex';
import { getters } from '@/constants/state';
import moment from 'moment';
import infiniteScroll from 'vue-infinite-scroll'

export default {
  directives: {infiniteScroll},
  components: { Layout },
  data: () => ({
    loading: false,
    week: 0,
    schedules: null
  }),
  methods: {
    async loadHistory() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const { data } = await history(this.filter.jobFilterId, this.week);
      const { week, schedules } = data;
      this.schedules = {
        ...this.schedules,
        ...schedules
      };
      this.week = week;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      filter: getters.SESSION_MAIN_FILTER_GETTER
    })
  },
  filters: {
    nlDateFormat(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    timeSpan(job, type) {
      return type === 'old'
        ? `(${job.start}:00-${job.ending}:00)`
        : `(${moment(job.start).format('HH:mm')}-${moment(job.ending).format('HH:mm')})`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
